.infoBox > img {
    visibility: hidden;
}

.timeline {
    position: absolute;
    top: 0;

    width: 20.5%;
    height: 100%;
    min-width: 310px;
}

.searchBar {
    position: absolute;
    top: 13.5px;
    right: 60px;

    width: 300px;
}

.controlPanel {
    position: absolute;
    top: 65px;
    right: 12px;

    width: 35px;
}