@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scroll-div {
  overflow-y: auto;
}

.page-container {
  display: flex;

  flex: 1;
  flex-direction: column;
  
  min-height: 0;
}

.page-header-contents {
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.page-header {
  display: block;
  height: 32px;
}

.app-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.app-frame {
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.transparent-button {
  border: none;
  vertical-align: middle;
  color: inherit;
  background-color: inherit;
  cursor: pointer;
}

.inputField {
    min-width: 195px;
    align-items: left;
}

.geofenceMarkerOverlayView {
  background: white;

  border-radius: 10px;
  border: 1px solid #ccc;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 1px;
}

.ant-card-small > .ant-card-body {
  padding: 0px;
}